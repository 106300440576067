import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { throttle } from 'underscore/modules/index'
import SocialShare from './SocialShare'
import { navigate } from 'gatsby'
import logo from '../../images/samloyd_portfolio_wrapped.png'


const StickyHeader = ({ header, coverImage, title, location, passRef, stickyHeaderHeight, style = {}, heightRef }) => {


    const [progress, setProgress] = useState(0)



    const measureHeight = (ref) => {
        if (!ref.current) return

        const element = ref.current;
        const { top, height } = element.getBoundingClientRect()
        const progress = -((top - 100) / ((height + 100) - window.innerHeight));
        setProgress(progress);
    }

    useEffect(() => {
        window.onscroll = throttle(() => measureHeight(heightRef), 200)
        return () => {
            window.onscroll = null
        }
    }, [heightRef])




    const progressWidth = (progress === 0 || !progress) ? "0%" : progress > 1 ? "100%" : `${(progress) * 100}%`
    return <div
        style={{ marginTop: `-${stickyHeaderHeight + 50 || 0}px`, opacity: progress > 0 ? 1 : 0, position: progress > 0 ? "sticky" : "fixed", ...style }}
        ref={passRef}
        className={`z-10 mb-12  w-full text-justify md:text-center top-0 transition-shadow duration-300 bg-gray-bg shadow-lg`}
    >
        <div className="pl-12 md:pl-0 max-w-6xl mx-auto py-6 flex relative" >
            <button
                onClick={() => navigate(location.state?.referrer || "/")}
                className="w-1/5 absolute self-center hidden md:block"> {" < Back"}
            </button>
            <h2 className="text-3xl max-w-4xl md:w-3/5 mx-auto self-center mb-0" >{header} </h2>
            <div className={`hidden lg:block w-1/5 absolute self-center right-0`}>
                <SocialShare coverImage={coverImage} altImage={logo} title={title} />
            </div>
        </div>
        <div className={`h-1 bg-highlight transition-all duration-75`} style={{ width: progressWidth }}></div>
    </div>
}

StickyHeader.propTypes = {
    header: PropTypes.string,
    coverImage: PropTypes.object,
    title: PropTypes.string,
    location: PropTypes.object,
    passRef: PropTypes.object.isRequired,
    heightRef: PropTypes.object.isRequired,
    stickyHeaderHeight: PropTypes.number,
    style: PropTypes.object,
}

export default StickyHeader
