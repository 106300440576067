import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useClipboard from '../../hooks/useClipboard'

const awaitWindow = typeof window !== 'undefined' ? window : {}

const SocialShare = ({ title, coverImage, altImage }) => {

    const img = coverImage ? <img alt={coverImage.title} width="0px" height="0px" className="overflow-hidden opacity-0" src={coverImage.meta} /> : <img alt={`Read ${title} on Sam Loyd's blog.`} width="0px" height="0px" className="overflow-hidden opacity-0" src={altImage} />

    const [showNote, setShowNote] = useState(false)
    const { copy } = useClipboard()
    const handleClick = () => {
        setShowNote(true)
        copy(awaitWindow.location)
    }


    // Handles the return to previous state
    useEffect(() => {
        if (showNote) {
            setTimeout(() => {
                setShowNote(false);
            }, 1000);
        }
    }, [showNote])

    const encodedUrl = encodeURIComponent(awaitWindow.location)


    return <div className="flex w-48 mx-auto justify-around text-2xl">
        <div 
            className="relative mr-6" 
            title="Copy link to clipboard"
            onClick={() => handleClick()}
            onKeyUp={(e) => e.keyCode === 32 || e.keyCode === 13  ? handleClick() : ""}
            tabIndex="0"
            role="button"
        >
            <i className={`absolute mt-1 mr-4 fas fa-share text-gray-900 hover:text-highlight hover:cursor-pointer self-center transition-all duration-200 transform ${showNote ? 'rotate-180 opacity-0 z-0' : 'rotate-0 opacity-1 z-10'}`}></i>
            <span role="img" aria-label="Copy Complete" className={`absolute transition-all duration-200 transform ${!showNote ? 'rotate-180 opacity-0 z-0' : 'rotate-0 opacity-1 z-10'}`}>👍</span>
        </div>

        <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://twitter.com/share?ref_src=${encodedUrl}`} title="Share to Twitter"
            data-show-count="false">
            <i className="fab fa-twitter text-gray-900 hover:text-highlight"></i>
            {img}
        </a>
        <a
            target="_blank"
            rel="noopener noreferrer"
            title="Share to LinkedIn"
            href={`http://www.linkedin.com/shareArticle?mini=true&url=${awaitWindow.location}&title=${title}&source=${awaitWindow.location?.origin}`}>
            <i className="fab fa-linkedin text-gray-900 hover:text-highlight"></i>
            {img}
        </a>

        <a title="Share to Facebook" target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&amp;src=sdkpreparse`}>
            <i className="fab fa-facebook text-gray-900 hover:text-highlight"></i>
            {img}
        </a>
    </div>
}

SocialShare.propTypes = {
    title: PropTypes.string,
    coverImage: PropTypes.object,
    altImage: PropTypes.string,
}

export default SocialShare
