import React, { useRef, useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import PostLayout from '../components/PostLayout'
import RichText from '../rich-text/RichText'
import Img from 'gatsby-image'
import useMarkDown from '../hooks/useMarkDown'
import PostCard from '../components/elements/PostCard'
import logo from '../images/samloyd_portfolio_wrapped.png'
import SocialShare from '../components/elements/SocialShare'
import StickyHeader from '../components/elements/StickyHeader'
import SubscriptionFull from '../components/NavBar/Subscription'




const PostTemplate = props => {
    const { path, location } = props;
    const { title, subtitle, tags = [], createdAt, body, coverImage, markdown } = props.data.post
    const [stickyHeaderHeight, setStickyHeaderHeight] = useState(0)

    const bodyRef = useRef({})
    const headerRef = useRef(null)

    const { similarArticles = [] } = props.data.post

    const fittedArticles = similarArticles && similarArticles.map(({ title, subTitle, coverImage, slug }) => {
        return {
            title,
            subTitle,
            fluid: coverImage ? { fluid: coverImage.fluid, title: coverImage.title } : false,
            slug,
        }
    })

    
    useEffect(() => {
        // window.onscroll = _.throttle( () => measureHeight(bodyRef), 100)
        window.onresize = () => { setStickyHeaderHeight(headerRef.current?.clientHeight)}
        if (stickyHeaderHeight !== headerRef.current?.clientHeight) return setStickyHeaderHeight(headerRef.current?.clientHeight)

        return () => {
            // window.onscroll = null
            window.onresize = null
        }
    }, [stickyHeaderHeight])

    // UseMarkDown returns false if given false
    const text = markdown ? markdown.text : false


    const markDownText = useMarkDown(text)



    return (
        <>
            <PostLayout path={path} title={title} image={coverImage?.meta.src} className="article" description={subtitle} location={location} article >
                <CoverImage fluid={coverImage ? coverImage.fluid : false} />
                <PostBody className={`relative h-full z-10 ${coverImage ? 'pt-250 sm:pt-350 lg:pt-400 xl:pt-400' : 'pt-12'}`} >
                    <Header header={title} preHeader={createdAt} coverImage={coverImage} tags={tags} />
                    <StickyHeader
                        heightRef={bodyRef}
                        style={{ opacity: stickyHeaderHeight !== 0 ? 1 : 0 }}
                        passRef={headerRef}
                        stickyHeaderHeight={stickyHeaderHeight}
                        location={location}
                        header={title}
                        coverImage={coverImage}
                        title={title}
                        />
                    {stickyHeaderHeight !== 0 ?

                        <div ref={bodyRef} className="pb-8 lg:pb-24">
                            {
                                markDownText ||
                                <RichText text={body.json} />}
                        </div> : ""
                    }

                </PostBody>

           
            </PostLayout>
            <PostFooter similarArticles={fittedArticles} coverImage={coverImage} title={title} />
        </>
    )
}

const PostBody = ({ children, className, style }) => {
    return <div className={className} style={style}>
        {children}
    </div>
}



const PostFooter = ({ similarArticles, coverImage, title, addNotification }) => {
    return <footer className="mb-8 pb-32 max-w-2xl md:mx-auto mx-2 ">
        <SocialShare coverImage={coverImage} altImage={logo} title={title}/>
        <hr className="my-12 mb-24 md:mb-12 max-w-2xl mx-auto border border-highlight" />
        <h3 className="mb-6 max-w-2xl">Thanks for reading!</h3>
        <p className="max-w-2xl">I hope you enjoyed this article. If you feel like other people would enjoy this content, or would benefit from reading it then please share using the links above or directly.</p>
        {similarArticles ?
            <>
                <h4 className="mt-8 md:mt-16">Other articles you might like</h4>
                <div className="">

                    {similarArticles.map(({ fluid, slug, title, subTitle }) =>
                        <>
                            <PostCard
                                key={slug}
                                coverImage={fluid ? fluid : false}
                                linkTo={`posts/${slug}`}
                                header={title}
                                subHeader={subTitle}
                                className="w-full my-2" />
                        </>
                    )}
                </div>
            </>

            : ""



        }
        <SubscriptionFull className="mt-8 md:mt-16 max-w-2xl mx-auto bg-gray shadow-subtle transition-shadow duration-500 hover:shadow-xl p-10 md:p-16 bg-white" />
    </footer>
}


const CoverImage = ({ fluid }) => {
    const image = fluid ? <Img className="absolute xl:h-500 sm:h-400 lg:h-500 h-300 z-10" fluid={fluid} /> : ""
    return image ? <div className="w-full absolute xl:h-500 sm:h-400 lg:h-500 h-300">
        {image}
    </div> : ""
}
const Header = ({ header, preHeader, subtitle, tags }) => {

    return <>
        <div className="z-40 bg-gray-bg max-w-5xl mx-auto lg:pt-32 pt-12 rounded-lg lg:rounded-none"> </div>
        <div className="z-40 bg-gray-bg relative mx-auto">
            <h6 className="text-base font-body text-gray-600">{preHeader}</h6>
            <h1 className="text-4xl">{header} </h1>
            {subtitle ? <h3>{subtitle}</h3> : ""}
            <Tags tags={tags} />
        </div>
        <div className="h-2 z-20 relative w-full bg-gray-bg " style={{ top: -75 }}></div>

    </>
}

const Tags = (props) => {
    return <div className="max-w-3xl mx-auto px-4 my-4">{props.tags ? props.tags.map(tag => <span key={tag} className="text-highlight font-bold">#{tag} </span>) : ""} </div>
}

export const SectionQuery = graphql`
    query Post($id: String!) {
        post: contentfulPost(id: {eq: $id}) {
            id
            slug
            subtitle
            coverImage {
                fluid(maxWidth: 1600, quality: 80) {
                ...GatsbyContentfulFluid
                }
                meta: resolutions(width: 400, quality:80) {
                    src
                }
            }
            title
            tags
            daysSince: createdAt(fromNow: true)
            createdAt(formatString: "dddd, MMMM Do YYYY, h:mm:ss a")
            body {
                json
            }
            markdown {
                text: markdown
            }
            updatedAt
            category {
                name
            }
            similarArticles {
                slug
                subtitle
                title
                coverImage {
                    fluid(maxWidth: 200, quality: 80) {
                        ...GatsbyContentfulFluid
                    }
                    title
                }
            }
        }
    }
`


export default PostTemplate
