import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import SEO from './SEO'
import { MobileNav, PostNav } from "./Nav"


const PostLayout = (props) => {

    const data = useStaticQuery(graphql`
    query PostLayoutQuery {
        nav: allContentfulNav {
            nodes {
                id
                description
                socialLinks {
                faIcon
                icon {
                    title
                    fixed(width: 100, height: 100, quality: 100) {
                        src
                    }
                }
                linkTo
                text
                }
                profile {
                    fixed(width: 100, height: 100, quality: 100) {
                        ...GatsbyContentfulFixed
                    }
                title
                }
                links {
                    faIcon
                    icon {
                        title
                        file {
                            url
                        }
                    }
                    linkTo
                    text
                }
                categories {
                    name
                    slug
                    id
                    post {
                        id
                    }
                }
                emailSubscription {
                    buttonText: button
                    body: description {
                        description
                    }
                    header
                }
            }
        }
    }
    `)

    const { header, title, path, children, className, location, description, image } = props;
    const { nav } = data;
    return (
        <>
            <div className="mx-auto min-h-screen flex ">
                <SEO title={title} description={description} image={image || null} article />
                <MobileNav path={path} nav={nav} />
                <PostNav nav={nav} location={location} />
                <main className={`mx-auto ${className}`}>
                    {header ? <h1 className="text-3xl font-bold">{header}</h1> : ""}
                    <article >
                        {children}
                    </article>

                </main>

            </div>
        </>
    )
}

PostLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default PostLayout
